import React, { Fragment } from "react"
import { graphql } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import {
  HomeBanner,
  Description,
  Title,
  TextContainer,
  IntoImg,
  WhatWeBelieve,
  FullImg,
} from "../styles/home"
// import TechsContainer from "../components/techs"
// import OurServices from "../components/ourServices"
import OurValues from "../components/ourValues"
import AboutUs from "../components/AboutUs"
import Ideology from "../components/Ideology"
// import Approach from "../components/Approach"
// import HowWeWork from "../components/howWeWork"
import { Section, Container, SectionTitle, OutLineBtn } from "../styles/common"
import Layout from "../components/layout"
import HomeCubes from "../components/homeCube"
import SEO from "../components/seo"
import slide1 from "../images/landingpage/x2-min.png"
import slide2 from "../images/landingpage/x1-2-small-min.jpg"
import slide3 from "../images/landingpage/x1-1-small-min.jpg"
import Slider from "../components/Slide"
import homeBackground from "../images/landingpage/x-min.png"
import ExpertiseSlider from "../components/ExpertiseSlider"
import ReferencesSlider from "../components/ReferencesSlider"

const IndexPage = ({ data }) => (
  <Layout>
    <Fragment>
      <SEO
        title={`${data.site.siteMetadata.title} | x-hub.io`}
        keywords={[
          "xhub",
          "devoxx",
          "devoxxmorocco",
          "devoxx4kids",
          "morocco",
          "casablanca",
          "x-hub.io",
          "devc",
          "Application & Technologies",
        ]}
      />
      <HomeBanner>
        <Container flex spaceBetween flexCenter>
          <TextContainer>
            <ScrollAnimation
              animateOnce={true}
              delay={300}
              duration={0.5}
              animateIn="bounceInLeft"
              animateOut="bounceOutRight"
            >
              <Title>{data.site.siteMetadata.title}</Title>
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce={true}
              delay={600}
              duration={0.6}
              animateIn="bounceInLeft"
              animateOut="bounceOutRight"
            >
              <Description>
                 Probably the best digital accelerator in problem solving & tech excellence with DevOps & Agile styles of work
              </Description>
              <OutLineBtn to="/prez">Our brochure</OutLineBtn>
            </ScrollAnimation>
          </TextContainer>
          <IntoImg>
            <FullImg src={homeBackground} alt="home banner" />
            <Slider>
              <img alt="slid1" src={slide1} />
              <img alt="slide2" src={slide2} />
              <img alt="slide3" src={slide3} />
            </Slider>
          </IntoImg>
        </Container>
        <HomeCubes />
      </HomeBanner>

      {/* <Section padding="60px 0 80px" bgcolor="#f7f7f7">
        <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle
              transform="uppercase"
              space="30px 0 20px"
              lightChild
              color="black"
            >
              What we <div>believe</div>
            </SectionTitle>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <WhatWeBelieve>
              We believe in our xTalents, young passionate people always looking
              for new IT Challengers. Our Agile coding culture improves our
              expertise in solving our customer's problems, our teams will
              commit to your digital challenges.
            </WhatWeBelieve>
            <WhatWeBelieve>
              We truly believe CODE is the future and we will be the best
              partner to take you to the next level.
            </WhatWeBelieve>
          </ScrollAnimation>
        </Container>
      </Section> */}
      <ExpertiseSlider />
      {/*<Ideology />*/}
      <Section bgcolor="white">
        <Container>
          <OurValues />
        </Container>
      </Section>
      <ReferencesSlider />
      {/* <Approach /> */}
      {/* <HowWeWork />
      <Section bgcolor="#f0f0f0">
        <Container flex flexCenter>
          <SectionTitle block>
            <ScrollAnimation animateOnce={true} animateIn="flipInY">
              Technologies
              <br />
              and Tools
            </ScrollAnimation>
          </SectionTitle>
          <TechsContainer />
        </Container>
      </Section> */}
      <div>{/* <Image src="gatsby-astronaut.png" /> */}</div>
    </Fragment>
  </Layout>
)

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        brand
        description
      }
    }
  }
`
export default IndexPage
