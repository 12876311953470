import React from "react";
import SliderContainer from "./Slide";
import bankOfAfrika from "../images/clients/bank-of-africa-new.jpg";
import github from "../images/clients/github.png";
import cihBank from "../images/clients/cih-bank.png";
import cmi from "../images/clients/cmi.png";
import lydec from "../images/clients/lydec.png";
import m2t from "../images/clients/m2t.png";
import rcar from "../images/clients/rcar.png";
import bcp from "../images/clients/bcp.png";
import renault from "../images/clients/renault.png";
import societeGenerale from "../images/clients/societe_generale.png";
import wafaAssurence from "../images/clients/wafa-assurence.png";


const referencesListe = [rcar,bankOfAfrika,lydec,cihBank,bcp,github,renault,cmi,societeGenerale,m2t,wafaAssurence]

const ReferencesSlider = () =>{
    const sliderContainer = {
        width: "100%",
        height: "60px",
        alignItems: "center",
        background:"white",
        padding: "25px 0px",
        boxShadow: "0 15px 40px rgba(0, 0, 0, 0.13)"
    }
    const iconContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
        pauseOnHover: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    }
    return(
        <div style={sliderContainer}>
            <SliderContainer settings={settings}>
                {referencesListe.map((item)=>
                    <div style={{width:"100%", display: "flex", justifyContent: "center"}} key={referencesListe.indexOf(item)}>
                        <div style={iconContainer}>
                            <img src={item} alt={`${item} logo`} style={referencesListe.indexOf(item) === 8 
                            ? {height: "45px"}
                            : {height: "60px"}
                        }/>
                        </div>
                    </div>
                )}
            </SliderContainer>
        </div>
    )
}

export default ReferencesSlider