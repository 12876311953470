import styled from "styled-components"
import { Section } from "./common"

const FullImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${({ theme }) => theme.md`
    display: none;
`}
`

const HomeBanner = styled.div`
  background: ${({ theme }) => theme.primaryColor};
  position: relative;
  overflow: hidden;
  // width: 100vw;
  height: calc(100vh - ${props => props.theme.headerHeight});
  margin-top: ${props => props.theme.headerHeight};

  ${({ theme }) => theme.tb`
    max-height: 480px;
  `}
`

const Brand = styled.h1`
  font-weight: 900;
  text-transform: uppercase;
  font-size: 5rem;
  letter-spacing: -1.2px;
  color: ${props => props.theme.white};
`

const Title = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 0.5px;
  width: 100%;
  text-align: left;
  color: ${props => props.theme.white};

  ${({ theme }) => theme.sm`
    font-size: 2.2rem;
  `}
`

const Description = styled.p`
  font-weight: 300;
  text-align: justify;
  font-size: 1.1rem;
  font-family: Lato;
  margin: 30px 0 20px;
  line-height: 150%;
  color: ${props => props.theme.white};

  ${({ theme }) => theme.sm`
  font-size: 1rem;
`}
`

const WhatWeBelieve = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 145%;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #6f6f6f;
  letter-spacing: 0.8px;
  margin: 0 0 15px;
  ${({ theme }) => theme.sm`
    font-size: 15px;
`}
`
const IntoImg = styled.div`
  width: 46%;
  overflow: hidden;
  // align-self: flex-start;
  // max-height: 90%;
  overflow: hidden;
  position: relative;
  right: -55px;
  .slick-slider img {
    width: 100%;
    height: auto;
    display: block;
  }

  ${({ theme }) => theme.tb`
    right: 0;
  `}

  ${({ theme }) => theme.md`
    width: 80%;
    margin: 0 auto;
    display: none;
  `}
`

const TextContainer = styled.div`
  width: 45%;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.md`
    width: 100%;
    margin: 35px 0;
  `}
`

const TitleLetter = styled.span`
  color: ${props => props.theme.white};
  transition: transform 300ms ease-in-out;

  &:nth-child(1) {
    color: ${props => props.theme.primaryLightColor};
  }

  &:nth-child(2) {
    color: ${props => props.theme.secondaryColor};
  }

  &:nth-child(3) {
    color: ${props => props.theme.tertiaryColor};
  }
`

const TechAndTools = styled.ul`
  list-style: none;
  padding: 0;
  -ms-box-orient: horizontal;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  li {
    opacity: 0.7;
    margin: 20px 2%;
    filter: grayscale(100%);
    transition: opacity 0.5s, filter 0.5s, -webkit-filter 0.5s, moz-filter 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Section}:hover & {
      opacity: 1;
      filter: grayscale(0);
    }

    ${({ theme }) => theme.sm`
      width: 48%;
      margin: 15px 1%;
      justify-content: center;
      img {
        max-width: 100%;
      }
  `}
  }
`

// const Services = styled.div`
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   margin: 100px 0;

//   ${({ theme }) => theme.md`
//     margin: 20px 0 0;
// `}
// `

// const ServicesItem = styled.div`
//   width: 220px;
//   height: 220px;
//   padding: 10px;
//   box-sizing: border-box;
//   position: relative;
//   z-index: 1;
//   transition: transform 300ms;
//   &:after {
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     border: 3px solid rgba(255, 255, 255, 0.5);
//     /* background: #2b1d48;
//     background: linear-gradient(
//       180deg,
//       rgba(99, 52, 131, 1) 44%,
//       rgba(43, 29, 72, 1) 86%
//     ); */
//     background: rgba(0, 0, 0, 1);
//     box-shadow: inset 0 0 120px #000000;
//     transform: rotate(45deg);
//     z-index: 0;
//     transition: transform 300ms;
//   }

//   &:hover {
//     transform: scale(1.4);
//     z-index: 2;
//     &:after {
//       transform: rotate(0);
//     }
//   }

//   ${({ theme }) => theme.md`
//     width: 240px;
//     height: 240px;
//     margin: 48px;
//   `}

//   ${({ theme }) => theme.sm`
//     width: 100%;
//     height: auto;
//     padding: 30px;
//     margin: 0 0 20px;
//     transform: scale(1) !important;
//       &:after {
//         transform: rotate(0) !important;
//       }
//   `}
// `

// const ServiceInner = styled.div`
//   position: relative;
//   z-index: 1;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: center;
//   text-align: center;
// `

// const ServiceTitle = styled.h3`
//   color: rgba(206, 206, 206, 0.6);
//   font-size: 16px;
//   font-weight: 500;
//   text-transform: uppercase;
//   margin: 0;
//   transition: transform 0.3s;
//   ${ServicesItem}:hover & {
//     transform: scale(0.7) translateY(-15px);
//   }

//   ${({ theme }) => theme.sm`
//     transform: scale(1) translateY(0px) !important;
//     margin-bottom: 15px;
//   `}
// `

// const ServiceDescription = styled.p`
//   font-size: 15px;
//   line-height: 18px;
//   font-family: Lato;
//   margin: 0;
//   color: white;
//   opacity: 0;
//   display: none;
//   transition: all 0.3s;
//   ${ServicesItem}:hover & {
//     transform: scale(0.8);
//     opacity: 1;
//     display: block;
//   }

//   ${({ theme }) => theme.sm`
//     display:block;
//     opacity:1;
//     transform: scale(1) !important;
//   `}
// `
// const ServiceIcon = styled.img`
//   width: 64px;
//   display: block;
//   margin: 0 auto 20px;
//   transition: transform 0.3s;
//   ${ServicesItem}:hover & {
//     transform: scale(0.7);
//   }

//   ${({ theme }) => theme.sm`
//     transform: scale(1) !important;
//   `}
// `
// const ServiceNum = styled.span`
//   display: none;
//   position: absolute;
//   z-index: -1;
//   opacity: 0.2;
//   font-size: 80px;
//   color: white;
//   top: -40px;
//   width: 90%;
//   text-align: right;
//   font-weight: 700;
//   transition: all 300ms;
//   ${ServicesItem}:hover & {
//     // opacity: 0.7;
//     font-size: 60px;
//     top: -55px;
//   }
// `

const Values = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const ValuesItem = styled.div`
  width: 23%;
  margin-bottom: 80px;
  & > div {
    height: 100%;
    box-sizing: border-box;
    padding: 35px;
    background-color: #fff;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
  }
  ${({ theme }) => theme.sm`
    width: 95%;
    margin: 0 auto 30px;
  `}
`
const ValuesItemContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-content: center;
`


const ValueTitle = styled.h3`
  font-size: 20px;
  font-weight: 900;
  font-family: Lato;
  
  margin: 0 0 15px;
  color: black;// text-transform: uppercase;
  text-align: center;
  // padding-bottom: 10px;
  // position: relative;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 30%;
  //   height: 3px;
  //   background: ${props => props.theme.quaternaryColor};
  //   left: 50%;
  //   bottom: 0;
  //   transform: translateX(-50%);
  // }
`

const ValueDescription = styled.p`
  font-size: 15px;
  line-height: 25px;
  margin: 0;
  font-family: Lato;
`
const IconContainer = styled.div`
  width: 100%;
  height : 50%;
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
`
const ValueIcon = styled.img`
  max-width: 90px;
  display: block;
  margin: 0 auto 10px;
`
const WorkDescription = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: white;
`

const WorkTitle = styled.h3`
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 30px 0 10px;
  color: ${props => props.theme.white};
`

const WorkIcon = styled.img`
  // display: block;
  // max-width: 90px;
  // height: auto;

  ${({ theme }) => theme.sm`
    display: block;
    max-width: 50px;
    height: auto;
  `}
`
const HowWork = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 65%;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.md`
    width: 100%;
  `}
`
const HowWorkMainTitle = styled.div`
  width: 20%;
  ${({ theme }) => theme.md`
    width: 100%;
  `}
`
const WorkItem = styled.div`
  width: 30%;

  ${({ theme }) => theme.md`
    width: 33%;
    padding: 0 1.5%;
    box-sizing: border-box;
  `}

  ${({ theme }) => theme.sm`
    width: 90%;
    margin: 0 auto 30px;
  `}
`

export {
  FullImg,
  HowWorkMainTitle,
  ValueIcon,
  WorkItem,
  HowWork,
  WorkIcon,
  WorkTitle,
  WorkDescription,
  ValueDescription,
  HomeBanner,
  Brand,
  Title,
  TitleLetter,
  Description,
  TextContainer,
  IntoImg,
  ValuesItemContent,
  TechAndTools,
  IconContainer,
  // ServiceInner,
  // ServiceTitle,
  // ServiceIcon,
  // Services,
  // ServicesItem,
  // ServiceNum,
  // ServiceDescription,
  Values,
  ValuesItem,
  ValueTitle,
  WhatWeBelieve,
}
